export default [
	{
		path: '/jurisdiction/cloud_manage',
		name: 'JurisdictionCloudManage',
		component: () => import('@/views/jurisdiction/cloud_manage'),
		meta: {
			title: '角色管理',
			keepAlive: false
		}
	},
	{
		path: '/jurisdiction/role_manage',
		name: 'JurisdictionRoleManage',
		component: () => import('@/views/jurisdiction/role_manage'),
		meta: {
			title: '账号管理',
			keepAlive: false
		}
	}
]
