import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "./home"
import Login from './login'
import Cusomer from './customer'
import Saas from './saas'
import Device from './device'
import Plugin from './plugin'
import Ai from './ai'
import Packaging from './packaging'
import Jurisdiction from './jurisdiction'
import Version from "./version"
import CapacitiveCloud from './capacitiveCloud'
import AiPackage from './aiPackage'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    ...Home,
    ...Login,
	...Cusomer,
	...Saas,
    ...Device,
	...Plugin,
	...Ai,
	...Packaging,
	...Jurisdiction,
    ...Version,
	...CapacitiveCloud,
	...AiPackage
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
