export default [
    {
        path:'/customer/site',
        name:'site',
        component:()=>import('../views/customer/site_list'),
        meta:{
            title:'站点管理',
            keepAlive:false
        }
    },
	{
		path:'/customer/custom',
		name:'custom',
		component:()=>import('../views/customer/custom_list'),
		meta:{
			title:'客户列表',
			keepAlive:false
		}
	},
	{
		path:'/customer/record',
		name:'record',
		component:()=>import('../views/customer/record_list'),
		meta:{
			title:'操作记录',
			keepAlive:false
		}
	},
	{
		path:'/customer/private',
		name:'private',
		component:()=>import('../views/customer/private_version'),
		meta:{
			title:'操作记录',
			keepAlive:false
		}
	}
]
