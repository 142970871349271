export default [
	{
		path: '/packaging/list',
		name: 'PackagingList',
		component: () => import('@/views/packaging/list'),
		meta: {
			title: '插件列表',
			keepAlive: false
		}
	},
]