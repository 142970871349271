import Home from "../views/home/Home"

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
            title:'控制台',
            keepAlive:false
        }
    }
]
