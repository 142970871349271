export default [
    {
        path:'/version/list',
        name:'VersionList',
        component:()=>import('../views/version/list'),
        meta:{
            title:'版本列表',
            keepAlive:false
        }
    },{
        path:'/version/fromData',
        name:'VersionFromData',
        component:()=>import('../views/version/form_data'),
        meta:{
            title:'新增-编辑版本信息',
            keepAlive:false
        }
    },
]