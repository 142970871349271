export default [
    {
        path:'/login',
        name:'Login',
        component:()=>import('../views/login/login'),
        meta:{
            title:'登录页',
            keepAlive:false
        }
    }
]
