export default [
	{
		path: '/plugin/list',
		name: 'PluginList',
		component: () => import('@/views/plugin/list'),
		meta: {
			title: '插件列表',
			keepAlive: false
		}
	},
	{
		path: '/plugin/add',
		name: 'PluginAdd',
		component: () => import('@/views/plugin/add'),
		meta: {
			tittle: '插件添加',
			keepAlive: false
		}
	},
	{
		path: '/plugin/edit',
		name: 'PluginEdit',
		component: () => import('@/views/plugin/edit'),
		meta: {
			tittle: '插件编辑',
			keepAlive: false
		}
	}
]