export default [
    {
        path:'/device/category',
        name:'DeviceCategory',
        component:()=>import('../views/device/category'),
        meta:{
            title:'设备分类',
            keepAlive:false
        }
    },
    {
        path:'/device/brand',
        name:'DeviceBrand',
        component:()=>import('../views/device/brand'),
        meta:{
            title:'设备厂家',
            keepAlive:false
        }
    },
    {
        path:'/device/type',
        name:'DeviceType',
        component:()=>import('../views/device/type'),
        meta:{
            title:'设备型号',
            keepAlive:false
        }
    },
    {
        path:'/device/list',
        name:'DeviceList',
        component:()=>import('../views/device/list'),
        meta:{
            title:'设备列表',
            keepAlive:false
        }
    },
    {
        path:'/device/package',
        name:'DevicePackage',
        component:()=>import('../views/device/device_package'),
        meta:{
            title:'流量套餐',
            keepAlive:false
        }
    },
    {
        path:'/device/manage',
        name:'DevicePackage',
        component:()=>import('../views/device/manage'),
        meta:{
            title:'流量管理',
            keepAlive:false
        }
    },
    {
        path:'/device/operation',
        name:'DeviceOperation',
        component:()=>import('../views/device/operation'),
        meta:{
            title:'设备操作',
            keepAlive:false
        }
    }
]
