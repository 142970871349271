export default [
	{
		path:'/saas/saas',
		name:'saas',
		component:()=>import('../views/saas/saas_list'),
		meta:{
			title:'Sass管理',
			keepAlive:false
		}
	}
]