<template>
<div class="menu-main">
  <div class="menu-item" :class="{'active':isSelect}">
    <a class="menu-a" href="javascript:;" @click="changeIndex">
      <i class="menu-icon iconfont icon-baobiaokongzhitai"></i>
      <span v-if="menuMode=='default'">控制台</span>
    </a>
  </div>
  <div class="menu-item" v-for="(item,index) in menuItem" :key="index" :class="{'active':isActive==index,'select':isActive==index && item.children.length}">
    <a href="javascript:;" class="menu-a" @click="selectMenu(index)">
      <i class="menu-icon iconfont" :class="item.icon"></i>
      <span class="menu-label" v-if="menuMode=='default'">{{item.name}}</span>
      <i v-if="menuMode=='default'" class="menu-down" :class="{'iconfont icon-xiala1':item.children.length}"></i>
    </a>
    <div class="children" v-if="menuMode=='default'">
      <div class="c-item" v-for="(items,keys) in item.children" :key="keys">
        <a class="c-item-a" :class="{'sel-a':isChildren==keys}" href="javascript:;" @click="openUrl(items,keys)">{{items.name}}</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "LeftMenu",
  data() {
    return {
      menuMode:'default',
      menuItem: [],
      isActive: -1,
      isSelect:-1,
      isChildren:-1,
    }
  },
  created() {
    this.init();
  },
  methods:{
    //获取栏目
    init(){
      var _this = this;
      this.requestApi('/adm/get_menu').then(function (res) {
        if (res.status){
          _this.menuItem = res.data;
        }
      })
    },
    selectMenu(key) {
      this.isSelect = false;
      if (this.isActive == key) this.isActive = -1;
      else this.isActive = key;
      if (!this.menuItem[key].children.length) this.toUrl(this.menuItem[key].url);
    },
    openUrl(item,index){
      this.isChildren = index;
      if(!item.url) return false;
      else this.toUrl(item.url);
    },
    changeIndex(){
      this.isActive = -1;
      this.isChildren = -1;
      this.isSelect = true;
      this.toUrl('/');
    },
  }
}
</script>

<style scoped>

</style>