export default [{
	path: '/ai/list',
	name: 'aiList',
	component: () => import('../views/ai/list'),
	meta: {
		title: 'ai管理',
		keepAlive: false
	}
},{
	path: '/ai/record',
	name: 'aiRecord',
	component: () => import('../views/ai/record'),
	meta: {
		title: 'ai购买记录',
		keepAlive: false
	}
},{
	path: '/ai/statistics',
	name: 'aiStatistics',
	component: () => import('../views/ai/statistics'),
	meta: {
		title: 'ai使用统计',
		keepAlive: false	
	}
}]
