import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ViewUI from 'view-design'
import './comfun'
import 'view-design/dist/styles/iview.css'
import store from './store'

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    const title = localStorage.getItem('station_title')
    document.title = title ? title + '-' + to.meta.title : '寻疗管理' + '-' +to.meta.title
  }
  next();
})

Vue.use(ViewUI)
new Vue({
  el:'#app',
  router,
  store,
  render: h => h(App)
})
