export default [{
	path: '/capacitiveCloud/packageManage',
	name: 'CapacitiveCloudPackageManage',
	component: () => import('../views/capacitiveCloud/package_manage'),
	meta: {
		title: '容联云套餐-套餐管理',
		keepAlive: false
	}
}, {
	path: '/capacitiveCloud/orderList',
	name: 'CapacitiveCloudOrderList',
	component: () => import('../views/capacitiveCloud/order_list'),
	meta: {
		title: '容联云套餐-订单列表',
		keepAlive: false
	}
}]
